import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { ScrollToTopOnMount } from '../../ScrollToTopOnMount';
import { VarauksetState } from '../../redux/store';
import { ContentContainer } from '../ContentContainer';
import MyBalance from './MyBalance';
import MyBookmarkedOrganizations from './MyBookmarkedOrganizations';
import MyReservationsLinkSection from './MyReservationsLinkSection';
import MyUser from './MyUser';

export const MyAccount: React.FC = (props) => {
  const user = useSelector((state: VarauksetState) => state.user.activeUser);
  const showContractLink = user?.level === 1 || (user?.managedOrganizations.length || 0) > 0;

  return (
    <ContentContainer>
      <ScrollToTopOnMount />
      <div className="row">
        <div className="col-md-6">
          <MyBookmarkedOrganizations />
          <br />
        </div>
        <div className="col-md-6">
          <MyBalance />
          <br />
          <MyUser />
          <br />
          <MyReservationsLinkSection />
          {showContractLink && (
            <>
              <br />
              <a href={`${process.env.PUBLIC_URL}/Varaukset-fi_sopimus_11-2022.pdf`} download>
                <FontAwesomeIcon icon={faFilePdf} /> Varaukset.fi-sopimusehdot (2022/11)
              </a>
            </>
          )}
        </div>
      </div>
    </ContentContainer>
  );
};

export default MyAccount;
